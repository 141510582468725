import React from 'react'
import { graphql } from 'gatsby'
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout'
import Previewable from '../components/Previewable'
import SEO from '../components/SEO'
import { PageHeader } from '../components/PageHeader'
import { RenderShortcode } from '../components/RenderShortcode'
import * as AcfLayout from '../components/Acf'
import { decodeEntities } from '../utils/helpers'
import './post.scss'

/* This page is named Project, however it represents the Case Study CPT */

const AcfComponent = ({ location, componentName, item, pageContext }) => {
  // If component does not exist in acf folder, print error message
  if (item == null) return null
  if (item) {
    const ComponentName = AcfLayout[componentName]
    const componentTitle = item.__typename
      ? item.__typename.replace('WordPressAcf_', '')
      : 'No name'
    if (!ComponentName) {
      return (
        <section className="page missing">
          <div className="inner">
            The ACF component <strong>"{componentTitle}"</strong> is missing.{' '}
            <span>&#128540;</span>
          </div>
        </section>
      )
    }
    return (
      <ComponentName location={location} {...item} pageContext={pageContext} />
    )
  }
}

export const ProjectPostTemplate = ({
  content,
  categories,
  title,
  date,
  author,
}) => {
  return null
  return (
    <section className="single-post">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <div className="wrap">
              <RenderShortcode content={content} />
            </div>
            {categories && categories.length ? (
              <div className="taxonomy">
                <h4>Categories</h4>
                <ul className="taglist">
                  {categories.map(category => (
                    <li key={`${category.slug}cat`}>
                      {category.slug && (
                        <GatsbyLink to={`/case-studies/${category.slug}/`}>
                          {decodeEntities(category.name)}
                        </GatsbyLink>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

const ProjectPost = props => {
  const { data, location, pageContext } = props
  const { wordpressPost: post, wordpressWpSettings } = data
  let acf = {}

  if (post != null) {
    if (post.acf != null) {
      acf = post.acf
    }
  } else if (post == null) {
    return null
  }

  const { layout } = acf || {}

  if (!post) return null
  const {
    title,
    content,
    featured_image_url,
    yoast,
    categories,
    date,
    author,
  } = post
  return (
    <Layout>
      <SEO
        title={
          decodeEntities(yoast.metaTitle)
            ? decodeEntities(yoast.metaTitle)
            : `${decodeEntities(title)} | ${decodeEntities(
                wordpressWpSettings.title
              )}`
        }
        desc={yoast.metaDescription}
      />
      {layout ? (
        layout.map((item, index) => {
          if (!item.__typename) return null
          const layoutComponentName = item.__typename.replace(
            'WordPressAcf_',
            ''
          )
          return (
            <AcfComponent
              key={index}
              componentName={layoutComponentName}
              item={item}
              pageContext={pageContext}
              location={location}
            />
          )
        })
      ) : (
        <PageHeader
          headerTitle={title}
          headerSubTitle={date}
          headerBackgroundImage={featured_image_url}
          location={location}
        />
      )}
      <ProjectPostTemplate
        content={content}
        categories={categories}
        title={title}
        date={date}
        author={author}
        blogSlug="case-studies"
      />
    </Layout>
  )
}

export default Previewable(ProjectPost, 'case-studies')

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_case_studies {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    wordpressPost: wordpressWpCaseStudies(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_image_url {
        source_url
      }
      categories {
        name
        slug
      }
      author {
        name
        slug
        avatar_urls {
          wordpress_48
        }
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
      acf {
        layout: layout_case_study_case_studies {
          ...FlexibleBlockQuery
          ...HeaderQuery
          ...HeaderHomeQuery
          ...TestimonialSliderQuery
          ...BlogFeedQuery
          ...GalleryQuery
          ...LogoFeedQuery
          ...LinkListQuery
        }
      }
    }
  }
`
